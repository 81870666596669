body {
  margin: 0;
  background-color: #1d1f24;
  color: #f0f0f0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.page {
  display: flex;
  flex-direction: row;
}
.App-header {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  color: white;
  font-size: larger;
  text-align: center;
  flex-direction: column;
}

.App-header h1, .App-header p {
  padding: 2vh;
  overflow: hidden; 
  white-space: nowrap; 
  position: relative;
}


.App-header h1::after {
  content: ''; 
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #1d1f24;
  width: 100%;
  animation: reveal 0.5s steps(10, end) forwards; 
}


.App-header p::after {
  content: ''; 
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #1d1f24; 
  width: 100%;
  animation: reveal 1s steps(25, end) forwards;
  animation-delay: 0.5s; 
}

.title{
  margin-bottom: 10vh;
  font-size:xx-large;

}

@keyframes reveal {
  from {
    width: 100%;
  }
  to {
    width: 0%; 
  }
}


.special{
  font-size: larger;
  text-align: center;
  letter-spacing: .30em; 
  margin-bottom: 10vh;

}
.special.Contact{
  margin-bottom: 0;
}

.special p{

  width: 90%;
}

h1, p{

  margin: 0 auto; 
  letter-spacing: .15em; 

}


@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.fade-in {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-in.visible {
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}


.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}




.side-menu {
  top: 0;
  position: fixed;
  right: -10vw;
  width: 10vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 10;
  transform: translateX(0); 
  opacity: 0; 
  transition: transform 1s ease, opacity 1s ease;
}

.side-menu.visible {
  transform: translateX(-10vw);
  opacity: 1;
}

.side-menu.hidden {
  transform: translateX(0);
  opacity: 0;
}

.menu-button {
  background: none;
  border: none;
  color: #cccccc; 
  font-size: 1.5vh;
  cursor: pointer;
  text-align: center;
  padding: 0.5vh 0; 
  transition: color 0.3s ease;
}
.button{
  letter-spacing: .30em; 
}
.menu-button.active-button {
  border: 2px solid #61dafb; 
  border-radius: 5px; 
  padding: 0.3vh; 
  transition: border 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scale(1.1); 
}


.menu-button:hover {
  color: #ffffff; 
}

.contents {
  display: flex;
  flex-direction: column;
  
  width: calc(100vw - 10vw);
  padding: 2vh;
  align-items: center;
  margin-right: 10vw; 
}

/* content Boxes */
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35vh;
  width: 100%; 
}

.content.last{
  margin-bottom: 20vh;
}

.content.right {
  flex-direction: row-reverse; 
}

.content-content.left {
  justify-content: flex-start; 
  text-align: left;
  max-width: 40%;
  flex: 1;
}

.content-content.right {
  justify-content: flex-end; 
  text-align: right;
  max-width: 40%;
  flex: 1;
}
h2{
  display:flex;
  text-align:center;
}
.content-content h2 {
  font-size: 2.5vh;
  margin-bottom: 1vh;
  color: #ffffff; 
}

.content-content p {
  
  font-size: 1.8vh;
  line-height: 1.5;
  margin-bottom: 2vh;
  color: #dcdcdc;
}

.content-content a {
  display: inline-block;
  padding: 1vh 2vw;
  background-color: #007bff;
  color: white;
  border-radius: 0.5vw;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.content-content a:hover {
  background-color: #0056b3; 
}


.content-image {
  flex: 1;
  text-align: center;
  max-width: 50%;
}

.content-image img {
  max-width: 100%;
  height: auto;
  border-radius: 1vw;
  border: 0.2vw solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); 
}


.show-menu-button {
  position: fixed;
  top: 10px;
  margin-bottom: 5px;
  right: 0;
  z-index: 20;
  background: none;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 5px;
}


.side-menu.visible {
  transform: translateX(-10vw); 
  opacity: 1;
}


.side-menu.hidden {
  transform: translateX(0);
  opacity: 0;
}


.content.special {
  flex-direction: column; 
  
  margin-bottom: 20vh;
} 


.content-content.special{
 
  justify-content: center;
  text-align: center;
  max-width: 50%;
}

.content-content.special p,.content-content.special h1{
  text-align: center;
  align-items: center;
}


@media (max-width: 700px) {
  h2{
    font-size: medium;
  }
  .content-content.special{
    text-align: center;
    max-width: 90%;
  }
  
  .contents {
    flex-direction: column;
    width: 100vw;
    margin-right: 0;
  }

  .content.left,.content.right {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20vh;

  }

  .content-image {
    margin-top: 15vh;
    max-width: 80%;
  }

  .content-content.left, .content-content.right{
    text-align: center;
    max-width:80%
   
  }
  .side-menu {
    background-color: #1d1f24;
    width: 20vw; 
  }

  .side-menu.visible {
    transform: translateX(-10vw);
    opacity: 0.7;
  }
}



logo {
  display: inline-flex; /* Flex for alignment */
  align-items: center;
  justify-content: center;
  text-decoration: none; /* Remove underline for links */
  color: inherit; /* Inherit color from parent, can override later */
  font-size: 5rem; /* Adjust icon size */
}

/* Hover effect for the logo */
logo:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  color: #61dafb; /* Change to React logo color on hover (optional) */
}

